<template>
  <div>
    <div id="sigma-container" ref="sigma-container"></div>
    <div id="search" style="display: none">
      <input
        type="search"
        id="search-input"
        ref="searchInput"
        list="suggestions"
        placeholder="Try searching for a node..."
      />
      <datalist id="suggestions" ref="suggestions"></datalist>
    </div>
  </div>
</template>

<script>
import data from "./data.json";
import data_etw from "./data_etw.json";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "App",
  components: {},
  props: [
    "newMetrics",
    "custom_range",
    "history_range",
    "dashboard",
  ],
  data() {
    return {
      index: 0,
      chartState: {
        hoveredNode: "",
        searchQuery: "",

        // State derived from query:
        selectedNode: "",
        suggestions: "",

        // State derived from hovered node:
        hoveredNeighbors: "",
      },
      chartData: {
        edges: [],
        nodes: [],
      },
      chartGraph: null,
      chartRenderer: null,
      intervalId: null,
      fixedPositions: {
        "198.18.56.100": {x: 25, y: 175},
        "198.18.56.33": {x: 5, y: 150},
        "73.107.6.163": {x: 75, y: 150},
        "198.18.56.103": {x: 75, y: 125},
        "198.18.56.138": {x: 10, y: 115},

        "192.168.0.200": {x: 25, y: 375},
        "192.168.55.201": {x: 325, y: 50},
        "195.128.225.81": {x: 30, y: 45},
        "195.128.225.180": {x: 80, y: 95},
        "20.150.61.37": {x: 130, y: 185},
        "40.127.240.158": {x: 150, y: 105},
        "20.150.61.38": {x: 230, y: 305},
        "195.128.225.2": {x: 320, y: 305},
      }
    };
  },
  watch: {
    newMetrics: function (newVal, oldVal) {
      this.handleNewMetrics(newVal);
    },
    history_range: function (newVal, oldVal) {
      console.log("pcw history_range,", newVal);
      if (newVal != "custom") {
        // this.loadChartHistory();
      }
    },
    dashboard: function (newVal, oldVal) {
      // this.loadChartHistory();
    },
    counterFilter: function (newVal, oldVal) {
      this.toggleGraph(newVal);
    },
    custom_range: function (newVal, oldVal) {
      console.log("custom_range", newVal, oldVal);
      // this.loadChartHistory();
    },
  },
  mounted() {
    this.sigmaChart();
    // this.builtChartDataFromETW();
    // this.intervalId = setInterval(this.syncChartData, 1000);
    // this.intervalId = setInterval(this.syncChartConnection, 10000);
  },
  unmounted() {
    clearInterval(this.intervalId);
  },
  methods: {
    handleNewMetrics(newMetrics) {
      const filtered = newMetrics.filter(item => item.m == "kernel.tcpipconnect" && item.et == "host" && item.sip)
      console.log("etw handleNewMetrics", filtered)
      
      this.builtChartDataFromETW(filtered)
    },
    syncChartConnection() {
      this.chartData.edges.map((edge) => {
        edge.attributes.size += 1; // _.random(0, 1);
        edge.attributes.label = "connection: " + edge.attributes.size;

        const random = _.random(1, 12);

        // update edge
        this.chartGraph.updateEdgeWithKey(edge.key, edge.source, edge.target, (attr) => {
          const newAttr = { ...attr };
          newAttr.size += 0.3
          newAttr.numSize += random
          newAttr.label = "connection: " + newAttr.numSize;

          return {
            ...newAttr,
          };
        });

        // update node if edge changed
        if (random > 0) {
          this.chartGraph.updateNode(edge.source, (attr) => {
            const newAttr = { ...attr };
            newAttr.size = newAttr.size + 0.3
  
            return {
              ...newAttr,
            };
          });
          this.chartGraph.updateNode(edge.target, (attr) => {
            const newAttr = { ...attr };
            newAttr.size = newAttr.size + 0.3
  
            return {
              ...newAttr,
            };
          });
        }
      });
    },
    builtChartDataFromETW(metrics) {
      const gropuByE = _.groupBy(metrics, (item) => item.e);
      console.log("gropuByE", gropuByE);
      const nodes = [];
      const edges = [];

      for (const key in gropuByE) {
        if (Object.hasOwnProperty.call(gropuByE, key)) {
          const element = gropuByE[key];

          const xAxisRandom = _.random(1, 400)
          const yAxisRandom = _.random(1, 400)
          if(this.fixedPositions[element[0].sip] == null) {
            this.fixedPositions[element[0].sip] = {
              x: xAxisRandom,
              y: yAxisRandom
            }
          }

          const node = {
            key: element[0].sip ? element[0].sip : element[0].e,
            attributes: {
              x: this.fixedPositions[element[0].sip].x,
              y: this.fixedPositions[element[0].sip].y,
              size: 5 + 1.5 * (element.length > 0 ? element.length : 0),
              label: `${key} (${element[0].sip})`,
              color: "#D8482D",
            },
            element: element,
          };

          this.chartGraph.mergeNode(node.key, node.attributes);

          nodes.push(node);
        }
      }

      nodes.map((item) => {
        const elements = item.element;
        const groupByIps = _.groupBy(elements, (item) => item.sip + item.dip);
        for (const key in groupByIps) {
          if (Object.hasOwnProperty.call(groupByIps, key)) {
            const ele = groupByIps[key];

            const foundNode = nodes.find(n => n.key == ele[0].dip)
            if (foundNode == null) {
              const xAxisRandom = _.random(1, 400)
              const yAxisRandom = _.random(1, 400)
              if(this.fixedPositions[ele[0].dip] == null) {
                this.fixedPositions[ele[0].dip] = {
                  x: xAxisRandom,
                  y: yAxisRandom
                }
              }

              const node = {
                key: ele[0].dip ? ele[0].dip : ele[0].e,
                attributes: {
                  x: this.fixedPositions[ele[0].dip].x,
                  y: this.fixedPositions[ele[0].dip].y,
                  size: 20,
                  label: `(${ele[0].dip})`,
                  color: "#D8482D",
                },
              };

              this.chartGraph.mergeNode(node.key, node.attributes);
            }

            const edge = {
              key: uuidv4(),
              source: ele[0].sip ? ele[0].sip : ele[0].e,
              target: ele[0].dip,
              attributes: {
                size: ele.length,
                numSize: ele.length,
                type: "arrow",
                label: "connection: " + ele.length,
              },
            };

            edges.push(edge);
            this.chartGraph.mergeEdge(edge.source, edge.target, edge.attributes);
          }
        }
      });

      // this.chartData.nodes = nodes;
      // this.chartData.edges = edges;
      // console.log("this.chartData", this.chartData);
      // this.sigmaChart();
    },

    syncChartData() {
      this.axios
        .get("http://localhost/sigma-chart-api/index.php?index=" + this.index)
        .then((res) => {
          if (res.data != false) {
            // const nodes = res.data.nodes;
            // const edges = res.data.edges;
            // this.chartData.nodes.push(nodes);
            // this.chartData.edges.push(edges);

            this.chartData.nodes.push(res.data);
            const container = document.getElementById("sigma-container");
            container.innerHTML = "";
            this.sigmaChart();
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
      this.index = this.index + 1;
    },
    sigmaChart() {
      const that = this;
      const container = document.getElementById("sigma-container");
      const searchInput = document.getElementById("suggestions");
      const searchSuggestions = document.getElementById("suggestions");

      this.chartGraph = new this.$graph();
      this.chartGraph.import(this.chartData);
      // this.chartGraph.import(data);
      this.chartRenderer = new this.$sigma(this.chartGraph, container, {
        renderEdgeLabels: true,
      });

      console.log("this.chartGraph", this.chartGraph);
      console.log("this.chartRenderer", this.chartRenderer);
      console.log("this.chartGraph.updateEdge", this.chartGraph.updateEdge);

      searchSuggestions.innerHTML = this.chartGraph
        .nodes()
        .map(
          (node) =>
            `<option value="${this.chartGraph.getNodeAttribute(
              node,
              "label"
            )}"></option>`
        )
        .join("\n");

      // Bind search input interactions:
      this.$refs.searchInput.addEventListener("input", () => {
        that.setSearchQuery(this.$refs.searchInput.value || "");
      });
      this.$refs.searchInput.addEventListener("blur", () => {
        that.setSearchQuery("");
      });

      // Bind graph interactions:
      this.chartRenderer.on("enterNode", ({ node }) => {
        that.setHoveredNode(node);
      });
      this.chartRenderer.on("leaveNode", () => {
        that.setHoveredNode(undefined);
      });

      // Render nodes accordingly to the internal state:
      // 1. If a node is selected, it is highlighted
      // 2. If there is query, all non-matching nodes are greyed
      // 3. If there is a hovered node, all non-neighbor nodes are greyed
      this.chartRenderer.setSetting("nodeReducer", (node, data) => {
        const res = { ...data };

        if (
          that.chartState.hoveredNeighbors &&
          !that.chartState.hoveredNeighbors.has(node) &&
          that.chartState.hoveredNode !== node
        ) {
          res.label = "";
          res.color = "#f6f6f6";
        }

        if (that.chartState.selectedNode === node) {
          res.highlighted = true;
        } else if (
          that.chartState.suggestions &&
          !that.chartState.suggestions.has(node)
        ) {
          res.label = "";
          res.color = "#f6f6f6";
        }

        return res;
      });

      // Render edges accordingly to the internal that.chartState:
      // 1. If a node is hovered, the edge is hidden if it is not connected to the
      //    node
      // 2. If there is a query, the edge is only visible if it connects two
      //    suggestions
      this.chartRenderer.setSetting("edgeReducer", (edge, data) => {
        const res = { ...data };

        if (
          that.chartState.hoveredNode &&
          !this.chartGraph.hasExtremity(edge, that.chartState.hoveredNode)
        ) {
          res.hidden = true;
        }

        if (
          that.chartState.suggestions &&
          (!that.chartState.suggestions.has(this.chartGraph.source(edge)) ||
            !that.chartState.suggestions.has(this.chartGraph.target(edge)))
        ) {
          res.hidden = true;
        }

        return res;
      });
    },
    setSearchQuery(query) {
      const that = this;
      that.chartState.searchQuery = query;

      if (this.$refs.searchInput.value !== query)
        this.$refs.searchInput.value = query;

      if (query) {
        const lcQuery = query.toLowerCase();
        const suggestions = this.chartGraph
          .nodes()
          .map((n) => ({
            id: n,
            label: this.chartGraph.getNodeAttribute(n, "label"),
          }))
          .filter(({ label }) => label.toLowerCase().includes(lcQuery));

        // If we have a single perfect match, them we remove the suggestions, and
        // we consider the user has selected a node through the datalist
        // autocomplete:
        if (suggestions.length === 1 && suggestions[0].label === query) {
          that.chartState.selectedNode = suggestions[0].id;
          that.chartState.suggestions = undefined;

          // Move the camera to center it on the selected node:
          const nodePosition = this.chartRenderer.getNodeDisplayData(
            that.chartState.selectedNode
          );
          this.chartRenderer.getCamera().animate(nodePosition, {
            duration: 500,
          });
        }
        // Else, we display the suggestions list:
        else {
          that.chartState.selectedNode = undefined;
          that.chartState.suggestions = new Set(
            suggestions.map(({ id }) => id)
          );
        }
      }
      // If the query is empty, then we reset the selectedNode / suggestions that.chartState:
      else {
        that.chartState.selectedNode = undefined;
        that.chartState.suggestions = undefined;
      }

      // Refresh rendering:
      this.chartRenderer.refresh();
    },
    setHoveredNode(node) {
      const that = this;
      if (node) {
        that.chartState.hoveredNode = node;
        that.chartState.hoveredNeighbors = new Set(
          this.chartGraph.neighbors(node)
        );
      } else {
        that.chartState.hoveredNode = undefined;
        that.chartState.hoveredNeighbors = undefined;
      }

      // Refresh rendering:
      this.chartRenderer.refresh();
    },
  },
};
</script>

<style>
#sigma-container {
  width: 100%;
  height: 800px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  /* top: 150px; */
  top: 200px;
  left: 0;
  height: calc(100% - 150px);
}

#search {
  position: absolute;
  right: 1em;
  bottom: 1em;
}
</style>
